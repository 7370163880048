import { DeviceActivationEvent } from "./Device";

export enum CancelWhen {
  IMMEDIATELY = "immediately",
  PERIOD_END = "period_end",
};

export interface CancelDeviceRequest {
  subscriptionId: string;
  cancelWhen: CancelWhen;
};

export interface ActivationsFeedResponse {
  events: DeviceActivationEvent[];
}