import styled from 'styled-components';

// Contains a number of graphs
export const Graphs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GraphTitle = styled.h1`
  text-align: left;
  display: flex;
  margin: 0;
  font-size: 1.2em;
  color: #616161;
  margin-bottom: 10px;
`;

export const GraphDescription = styled.p`
  font-size: 0.8em;
  text-align: left;
  color: #7b7b7b;
`;

// A single graph
export const GraphContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

export const TimeRangeSelectorStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;
