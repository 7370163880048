import React, {useCallback, useEffect, useState} from 'react';
import User from './User';
import AuthContext from './AuthContext';
import ShieldGPSAxiosClient from '../ShieldGPSAxiosClient';
import { AxiosError } from 'axios';

const fetchUser = async () => {
  const response = await ShieldGPSAxiosClient.get('/admin/me')
  return response.data;
}

/**
 * AuthProvider gives access to current logged in user and signout functions. Also
 * responsible for periodically refreshing user, detecting session expiration etc.
 */
const AuthProvider: React.FC<{children: any}> = ({ children }) => {

  const [authToken, setAuthToken] = useState<string | null>(window.localStorage.getItem('co.shieldgps.authToken'));
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const signOut = useCallback(
    () => {
      console.log('Signing out...');
      window.localStorage.removeItem('co.shieldgps.authToken');
      setAuthToken(null);
      setUser(null);
      window.location.href = '/';
    },
    [],
  );

  const setAuthTokenWrapped = useCallback(
    (token: string) => {
      // Update local storage first
      window.localStorage.setItem('co.shieldgps.authToken', token);
      setAuthToken(token);
    },
    []
  );


  // Trigger a user refresh whenever the auth token changes
  useEffect(() => {
    if(authToken) {
      setIsLoading(true);
      // Load user first time

      fetchUser().then((user: any) => {
        setUser(user);
      })
      .catch((e: AxiosError) => {
        console.log('got an error');
        console.log(e);
        if(e.response?.status === 401) {
          console.log('Got 401 response, signing out...');
          signOut();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    } else {
      console.log('no auth token');
      setIsLoading(false);
    }
  }, [authToken, signOut]);

  // Setup refresh timer
  useEffect(() => {
    let timerId: any;

    if(authToken) {
      console.log('setting up auth refresh timer');
      timerId = setInterval(() => {
        console.log('Refreshing user...');
        fetchUser().then((user: any) => {
          setUser(user);
        })
        .catch((e: AxiosError) => {
          console.log('got an error');
          console.log(e);
          if(e.response?.status === 401) {
            console.log('Got 401 response, signing out...');
            signOut();
          }
        });
      }, 30000);
    }

    return () => clearInterval(timerId);
  }, [authToken, signOut]);

  const context = {
    user,
    isAuthenticated: !!user,
    isLoading,
    signOut,
    setAuthToken: setAuthTokenWrapped,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
};

export default AuthProvider;