import React, { useEffect, useState }from 'react';

import { Device, DeviceSubscription } from '../../models/Device';
import { CancelDeviceRequest, CancelWhen } from '../../models/Requests';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import { useMutation, useQueryClient } from 'react-query';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const cancelSubscription = async (cancelDeviceRequest: CancelDeviceRequest): Promise<DeviceSubscription | null> => {
  const response = await ShieldGPSAxiosClient.post<DeviceSubscription>(
    '/admin/user-search/cancel-subscription', cancelDeviceRequest);

  return response.data;
};

export const CancelDeviceDialog = ({ open, device, subscription, handleClose, handleConfirm }: {
  open: boolean,
  device: Device,
  subscription: DeviceSubscription,
  handleClose: any,
  handleConfirm: any,
}) => {
  const queryClient = useQueryClient();
  const [cancelWhen, setCancelWhen] = useState<CancelWhen>(CancelWhen.PERIOD_END);

  const cancelMutation =  useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      // Add a delay to allow the cancel event state to sync back to ShieldGPS platform via
      // Stripe webhooks.
      // TODO: Implment more robost mechanism (e.g polling)
      return new Promise<void>(resolve => {
        setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ['device-lookup', device.imei] })
          handleConfirm();
          resolve();
        }, 5000);
      });
    }
  });

  const handleCancel = () => {
    cancelMutation.mutate({
      subscriptionId: subscription.id.toString(),
      cancelWhen,
    });
  }

  const handleChangeCancelWhen = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelWhen((event.target as HTMLInputElement).value as CancelWhen);
  };

  // To handle the closing of a window (since closing does not actually unmount)
  useEffect(() => {
    if (!open) {
      cancelMutation.reset();
      setCancelWhen(CancelWhen.PERIOD_END);
    }
    // Intentionally leave closeMutation from dependency, since block itself changes it
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Cancel Subscription
      </DialogTitle>
      <DialogContent>
        <div style={{width: '500px', paddingTop: '10px' }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="cancel-when-radio"
              name="controlled-radio-buttons-group"
              value={cancelWhen}
              onChange={handleChangeCancelWhen}
              >
              <FormControlLabel value={CancelWhen.IMMEDIATELY} control={<Radio />} label="Immediately" />
              <FormControlLabel value={CancelWhen.PERIOD_END} control={<Radio />} label="End of billing period" />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      {cancelMutation.isError && (
            <Alert severity="error">Unable to cancel</Alert>
          )}
      <DialogActions>
        <Button onClick={handleClose}>
          Don't Cancel
        </Button>
        <LoadingButton onClick={handleCancel} loading={cancelMutation.isLoading} variant="contained" autoFocus>
          Cancel Subscription
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};