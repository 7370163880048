
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';

export const DeviceChargingIndicator = ({ charging }: { charging: boolean }) => {
  return charging ? (
    <PowerIcon fontSize='small' sx={{color: '#414552'}} />
  ) : 
  (
    <PowerOffIcon fontSize='small' sx={{color: '#414552'}} />
  );
}