import React, { useState } from 'react';
import styled from 'styled-components'
import { hasFlag } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {
  useNavigate,
} from "react-router-dom";
import { useQuery } from 'react-query';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import moment from 'moment';
import { User } from '../../models/User';
import { Device } from '../../models/Device';

import { DeviceStatusTag } from '../../components/device/DeviceStatusTag';
import { 
  Text,
  FieldNameText,
  FieldDescription,
  Box,
  BiggestText,
  SectionHeaderText,
  MainContainer,
  LeftColumn,
  RightColumn,
  StatusIndicator,
  capitalizeFirstLetter,
  DeviceStatusTinyLineChart,
  ClickToCopy,
} from '../../components/Common';
import { DeviceImage } from '../../components/device/DeviceImage';
import { BatteryIndicator } from '../../components/BatteryIndicator';
import {
  useParams,
} from "react-router-dom";

import Button from '@mui/material/Button';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';

import { UserImpersonateDialog } from './UserImpersonateDialog';
import { ChangeEmailDialog } from './ChangeEmailDialog';
import { DeviceRow } from '../../components/device/DeviceRow';
import { CircularProgress } from '@mui/material';

const UserSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserSummary = ({ user }: { user: User | undefined | null }) => {
  const hbc = user?.homebaseCountry || '--';

  return (
    <UserSummaryContainer>
      <UserSummaryField name="Country">
      {hasFlag(hbc) ? getUnicodeFlagIcon(hbc) : null} {hbc}
      </UserSummaryField>
      <UserSummaryField name="Since">
        {moment(user?.createdAt).format('MMM YYYY') || '--'}
      </UserSummaryField>
      <UserSummaryField name="MRR">
        {user?.mrr ? parseFloat(user.mrr + '').toFixed(2) : '--'}
      </UserSummaryField>
    </UserSummaryContainer>
  );
}; 

const UserSummaryField = ({ name, children }: { name: string; children: any }) => {
  return (
    <div>
      <FieldNameText>{name}</FieldNameText>
      {children}
    </div>
  );
}; 

const UserInfoColumn = ({ user }: { user: User | undefined | null }) => {
  return (
    <div>
      <Box>
        <BiggestText>{user?.firstName} {user?.lastName}</BiggestText>
        <ClickToCopy copyContent={user?.email || ''} >
          <Text>{user?.email}</Text>
        </ClickToCopy>
      </Box>

      <Box>
        <SectionHeaderText>Summary</SectionHeaderText>
        <UserSummary user={user} />
      </Box>

      <Box>
        <SectionHeaderText>Details</SectionHeaderText>
        <FieldDescription name="Platform">
          {user?.platform === 'tracksolid' ? (
            <StatusIndicator label="Tracksolid" isSuccess={!!user?.tracksolidUserId} />
          ) : (
            <span>
              {capitalizeFirstLetter(user?.platform)}
            </span>
          )}
        </FieldDescription>
        <FieldDescription name="Stripe">
          <a href={`https://dashboard.stripe.com/customers/${user?.stripeId}`} target="_blank" rel="noopener noreferrer">{user?.stripeId}</a>
        </FieldDescription>
        <FieldDescription name="Devices" value={`${user?.numActiveDevices}/${user?.numTotalDevices} active devices`} />
        <FieldDescription name="Total Spend" value={`${user?.totalSpend ? parseFloat(user?.totalSpend + '').toFixed(2) : '0.00'} ${user?.currency}`} />
        <FieldDescription name="Payment Method" value={user?.defaultPaymentMethod} />
        <FieldDescription name="Last Seen" value={moment(user?.lastseen).fromNow()} />
        <FieldDescription name="Language" value={user?.locale} />
      </Box>
    </div>
  );
}

// TOOD: Use MUI Menu instead - it has built in popover support
export default function UserActionsDropdown({ onActionSelect }: { onActionSelect: (action: string) => void }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant="contained" 
        endIcon={<KeyboardArrowDownRoundedIcon />}
        aria-haspopup="menu">
          Actions
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <MenuList>
            <MenuItem 
              key={1} 
              onClick={() => { 
                handleClose();
                onActionSelect('change_email');
              }}>
              Change Email
            </MenuItem>
            <MenuItem 
              key={2} 
              onClick={() => { 
                handleClose();
                onActionSelect('impersonate');
              }}>
              Impersonate
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}



const getUser = async (userId: string | number | undefined): Promise<User | null> => {
  if(!userId) {
    return null;
  }

  const response = await ShieldGPSAxiosClient.get<User>(`/admin/user-search/users/${userId}`);
  return response.data;
};

const getUserDevices = async (userId: string | number | undefined): Promise<Device[] | null> => {
  if(!userId) {
    return null;
  }

  const response = await ShieldGPSAxiosClient.get<Device[]>(`/admin/user-search/users/${userId}/devices`);
  return response.data;
};



export function UserViewPage() {
  const { userId } = useParams();
  const [ showImpersonateDialog, setShowImpersonateDialog ] = useState(false);
  const [ showChangeEmailDialog, setShowChangeEmailDialog ] = useState(false);

  const { data: user, isLoading: userIsLoading } = useQuery(
    ['user-lookup', userId],
    () => getUser(userId),
    {},
  );

  const { data: devices, isLoading: devicesIsLoading } = useQuery(
    ['user-device-lookup', userId],
    () => getUserDevices(userId),
    {},
  );

  const compareLastseenDays = (d1: Device, d2: Device): number => {
    const now = moment();

    const daysSinceD1 = d1.lastseen ? now.diff(moment(d1.lastseen), 'days') : Number.MAX_SAFE_INTEGER;
    const daysSinceD2 = d2.lastseen ? now.diff(moment(d2.lastseen), 'days') : Number.MAX_SAFE_INTEGER;
    return daysSinceD1 - daysSinceD2;
  };

  const orderedDevices = devices?.sort((d1, d2) => {
    return d1.state.localeCompare(d2.state) || compareLastseenDays(d1, d2) || d1.imei.localeCompare(d2.imei)
  });

  const handleUserActionSelect = (action: string) => {
    switch(action) {
      case 'impersonate': {
        setShowImpersonateDialog(true);
        break;
      }
      case 'change_email': {
        setShowChangeEmailDialog(true);
        break;
      }
    }
  }

  return (
    <>
      <MainContainer>
        <LeftColumn>
          {userIsLoading ? (<CircularProgress />) : (<UserInfoColumn user={user} />)}
        </LeftColumn>
        <RightColumn>
          <Box style={{textAlign: 'right'}}>
            <UserActionsDropdown onActionSelect={handleUserActionSelect}/>
          </Box>
          <Box>
            <SectionHeaderText>Devices</SectionHeaderText>
            <div>
              {devicesIsLoading && (
                <CircularProgress />
              )}
              
              {orderedDevices?.map(device => <DeviceRow key={device.imei} device={device} />)}
            </div>
          </Box>
        </RightColumn>
      </MainContainer>

      {user && 
        <UserImpersonateDialog
          user={user} 
          open={showImpersonateDialog}
          handleClose={() => setShowImpersonateDialog(false)}
        />}

      {user && 
        <ChangeEmailDialog
          user={user} 
          open={showChangeEmailDialog}
          handleConfirm={() => setShowChangeEmailDialog(false)}
          handleClose={() => setShowChangeEmailDialog(false)}
        />}
    </>
  );
};

