import React, { useEffect, useState, useContext }from 'react';
import AuthContext from '../lib/auth/AuthContext';
import {
  useNavigate,
} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styled, { css } from 'styled-components'

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import TimelineIcon from '@mui/icons-material/Timeline';
import LogoutIcon from '@mui/icons-material/Logout';
import { User } from '../models/User';
import ShieldGPSAxiosClient from '../lib/ShieldGPSAxiosClient';

const NavListBottom = styled.div`
  position: absolute;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
`;
const NavBarDiv = styled.div`
  display: flex;
  background-color: yellow;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
`;


// Flex: 1 not the same as flex-grow: 1
// https://stackoverflow.com/questions/31909188/flexbox-evenly-sized-elements-regardless-of-contents
const NavBarSection = styled.div`
  flex: 1;
  display: flex;
`;

const NavBarSectionMiddle = styled(NavBarSection)`
  flex: 3;
`;

const SearchForm = styled.form`
  width: 100%;
`;

const SearchTextInput = styled.input`
  max-width: 400px;
  width: 100%;
  padding: 8px;
  width: 100%;
  border: #bdbdbd 1px solid;
  border-radius: 4px;
  
`;

const VersionTag = styled.span`
  font-size: 11px;
  color: #b1b1b1;
`;

enum SearchQueryType {
  UNKNOWN_SEARCH,
  IMEI_SEARCH,
  ICCID_SEARCH,
  EMAIL_SEARCH,
}

const determineSearchQueryType = (query: string): SearchQueryType => {

  const emailRegExp : RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const imeiRegExp : RegExp = /^\d{15}$/;
  const iccidRegExp : RegExp = /^\d{19}$/;

  if (emailRegExp.test(query)) {
    return SearchQueryType.EMAIL_SEARCH;
  } else if (imeiRegExp.test(query)) {
    return SearchQueryType.IMEI_SEARCH;
  } else if (iccidRegExp.test(query)) {
    return SearchQueryType.ICCID_SEARCH;
  } else {
    return SearchQueryType.UNKNOWN_SEARCH;
  }
}


interface SearchUserResult {
  matches: User[];
}


const searchForUser = async (searchField: string, searchValue: string): Promise<SearchUserResult> => {
  const response = await ShieldGPSAxiosClient.post<SearchUserResult>('/admin/user-search', {
    searchField,
    searchValue,
  });
  return response.data;
};


const Navbar = () => {
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const normalizedSearchValue = searchValue.trim().toLowerCase();

    const queryType = determineSearchQueryType(normalizedSearchValue);
    console.log(queryType);
    if (queryType === SearchQueryType.IMEI_SEARCH) {
      navigate(`/devices/${normalizedSearchValue}`);
    } else if (queryType === SearchQueryType.ICCID_SEARCH) {
      navigate(`/devices/${normalizedSearchValue}?id_type=iccid`);
    } else if (queryType === SearchQueryType.EMAIL_SEARCH) {
      searchForUser('email', normalizedSearchValue)
        .then((result: SearchUserResult) => {
          if (result.matches.length === 0) {
            alert(`Couldn't find any matches for ${normalizedSearchValue}`);
            return;
          }

          const user = result.matches[0];
          navigate(`/users/${user.id}`)
        })
        .catch((e) => {
          console.error(e);
          alert(`Failed to lookup user ${normalizedSearchValue}`);
        });
    } else {
      alert('Not sure what type of search query youve entered');
    }
  }

  const [drawerVisible, setDrawerVisible] = useState(false);

  const authContext = useContext(AuthContext);

  const onDrawerClosed = () => {
    setDrawerVisible(false);
  }

  return (
    <>
      <NavBarDiv>
      <NavBarSection>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setDrawerVisible(true)}
        >
          <MenuIcon />
        </IconButton>
      </NavBarSection>

      <NavBarSectionMiddle>
        <SearchForm onSubmit={handleSubmit}>
          <SearchTextInput 
            type="text"
            placeholder="Search device or user..."
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)} />
        </SearchForm>
      </NavBarSectionMiddle>
      
      <NavBarSection>
      </NavBarSection>
     </NavBarDiv>

     <Drawer
        anchor={'left'}
        open={drawerVisible}
        onClose={onDrawerClosed}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setDrawerVisible(false)}
          >

          <List>
            <ListItem>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              {authContext.user?.firstName} {authContext.user?.lastName}
            </ListItem>

            <Divider style={{marginTop: '10px', marginBottom: '25px'}}/>
            
            <ListItem key={"Home"} disablePadding>
              <ListItemButton onClick={() => navigate('/')}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>

            <ListItem key={"Activations Feed"} disablePadding>
              <ListItemButton onClick={() => navigate('/activations')}>
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary={"Activations Feed"} />
              </ListItemButton>
            </ListItem>
          </List>


          <NavListBottom>
            <List>
              <ListItem key={"Logout"} disablePadding>
                <ListItemButton onClick={() => authContext.signOut()}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <VersionTag>
                  Version {process.env.REACT_APP_VERSION}
                </VersionTag>
              </ListItem>
            </List>
          </NavListBottom>
        </Box>
      </Drawer>
    </>

  );
}

export default Navbar;
