import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Device, DeviceConfig } from '../../models/Device';

import styled from 'styled-components';
import { DeviceConfigSyncStateIndicator } from '../../components/DeviceConfigSyncStateIndicator';


import { CircularProgress, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useGetDeviceConfigs } from '../../hooks/useGetDeviceConfigs';
import { CodeBlock } from '../../components/Common';


interface DeviceConfigTabProps {
  device: Device;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceConfigTitle = styled.div`
  font-weight: 600;
  display: flex;
  gap: 16px;
`;

const DeviceConfigContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 0.8em;
  color: rgb(0, 0, 0, 0.6);
  margin-bottom: 4px;
`;

const DeviceConfigAccordion: React.FC<{ config: DeviceConfig }> = ({ config }) => {

  if (!config.issuedCommands) {
    debugger;
  }
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <DeviceConfigTitle>V {config.id} <DeviceConfigSyncStateIndicator syncState={config.syncState} /></DeviceConfigTitle>
      </AccordionSummary>
      <AccordionDetails>
        <DeviceConfigContent>
          <div>
            <Title>Props</Title>
            <Table size="small" aria-label="">
              <TableBody>
                <TableRow key={0}>
                  <TableCell component="th" scope="row">
                    Created at
                  </TableCell>
                  <TableCell align="right">{new Date(config.createdAt).toLocaleString()}</TableCell>
                </TableRow>

                <TableRow key={1}>
                  <TableCell component="th" scope="row">
                    Sync state
                  </TableCell>
                  <TableCell align="right">{config.syncState}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          <div>
            <Title>Config</Title>
            <CodeBlock code={config.data} />
          </div>

          <div>
            <Title>Commands</Title>
            <Table size="small" aria-label="">
              <TableBody>
                {config.issuedCommands.map((command) => (
                  <TableRow key={command.id}>
                    <TableCell component="th" scope="row">
                      {command.command}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {command.status}
                    </TableCell>
                    <TableCell align="right">{command.response?.command}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </DeviceConfigContent>
      </AccordionDetails>
    </Accordion>
  );
};

export const DeviceConfigTab: React.FC<DeviceConfigTabProps> = ({ device }) => {

  const { data, isLoading, error } =
    useGetDeviceConfigs({ imei: device.imei });


  return (
    <Container>
      {isLoading ? (
        <CircularProgress />
      ) : (
        data && data.configs.map((config: DeviceConfig) => (
          <DeviceConfigAccordion key={config.id} config={config} />
        ))
      )}
    </Container>
  );
};


