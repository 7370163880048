import { DeviceConfigSyncState } from '../models/Device';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

export const DeviceConfigSyncStateIndicator = ({ syncState }: { syncState?: DeviceConfigSyncState }) => {
  // Never attempted a sync
  if (!syncState) {
    return null;
  }

  if (syncState === 'failed') {
    // SyncProblemIcon
    return <SyncProblemIcon fontSize='small' style={{ marginRight: '2px'}} sx={{ color: '#e5074a' }} />;
  }

  // all good
  if (syncState === 'received') {
    return <CheckCircleOutlineOutlinedIcon fontSize='small' style={{}} sx={{ color: '#006908' }} />;
  }

  // Sync must be happening, either 'new' or 'sent'
  return <SyncIcon 
    fontSize='small' 
    style={{ marginRight: '2px'}} 
    sx={{
      color: 'rgb(136, 132, 216)',
      animation: "spin 2s linear infinite",
      "@keyframes spin": {
        "0%": {
          transform: "rotate(360deg)",
        },
        "100%": {
          transform: "rotate(0deg)",
        },
      },
    }} />;
}