import { useContext, useState, useCallback } from 'react';
import AuthContext from '../lib/auth/AuthContext';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ShieldGPSAxiosClient from '../lib/ShieldGPSAxiosClient';

const ErrorMessageBox = ({ error }: {error: string}) => {

  return (
    <div className="errorBox">{error}</div>
  );
}

const LoginPage = () => {

  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleLogin = useCallback((e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    ShieldGPSAxiosClient.post('/admin/auth', {
      email,
      password,
    })
    .then((response) => {
      if(response?.data?.authResult) {
        authContext.setAuthToken(response?.data?.accessToken);
      } else {
        setError('Incorrect email address or password.');
      }
    })
    .catch((error) => {
      console.error(error);
      setError('Failed to login.');
    })
    .finally(() => {
      setIsLoading(false);
    })
  }, [email, password, authContext]);

  return (
    <div className="centerPanel">
      <div className="loginWindow">
        <h1>ShieldGPS</h1>
        <h2>Sign in to your account</h2>
        <Stack
          component="form"
          spacing={2}
          noValidate
          autoComplete="off"
          onSubmit={handleLogin}>
          <TextField 
            id="email"
            label="Email address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading} />
          <TextField 
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={isLoading} />
          {error && <ErrorMessageBox error={error} />}
          <LoadingButton
            loading={isLoading}
            type="submit" 
            variant="contained">Login</LoadingButton>
        </Stack>
      </div>
    </div>
  )
}

export default LoginPage;