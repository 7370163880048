import { useEffect }from 'react';

import { Device } from '../../models/Device';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import { useMutation } from 'react-query';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

const factoryResetQuery = async ({imei} :{imei: String}): Promise<Device | null> => {
  const response = await ShieldGPSAxiosClient.post<Device>(
    `/admin/user-search/devices/${imei}/factory-reset`);

  return response.data;
};

export const FactoryResetDeviceDialog = ({ open, device, handleClose, handleConfirm }: {
  open: boolean,
  device: Device,
  handleClose: any,
  handleConfirm: any,
}) => {

  const factoryResetMutation =  useMutation({
    mutationFn: factoryResetQuery,
    onSuccess: () => {
      return new Promise<void>(resolve => {
        setTimeout(() => {
          handleConfirm();
          resolve();
        }, 5000);
      });
    }
  });

  // To handle the closing of a window (since closing does not actually unmount)
  useEffect(() => {
    if (!open) {
      factoryResetMutation.reset();
    }
    // Intentionally leave closeMutation from dependency, since block itself changes it
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Factory Reset
      </DialogTitle>
      <DialogContent>
        <div style={{width: '500px', paddingTop: '10px' }}>
          Are you sure you want to reset? This will:
          <ol>
            <li>Immediately backup live positions to S3</li>
            <li>Archive the S3 positions</li>
            <li>Delete trips</li>
            <li>Delete voice calls</li>
            <li>Delete trusted numbers</li>
            <li>Delete position data</li>
          </ol>
          <p>
            The reset can take anywhere from a few seconds to a few minutes and will happen in the background.
            An event will be logged with the device when the reset is complete.
          </p>
        </div>
      </DialogContent>
      {factoryResetMutation.isError && (
            <Alert severity="error">Unable to perform factory reset</Alert>
          )}
      <DialogActions>
        <Button onClick={handleClose}>
          Don't Reset
        </Button>
        <LoadingButton onClick={() => factoryResetMutation.mutate({ imei: device.imei })} loading={factoryResetMutation.isLoading} variant="contained" autoFocus>
          Reset Device
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};