import { Device, DeviceSubscription } from "../models/Device";

/**
 * Filters a list of subscriptions to return only those that are 'valid'.
 */
export const validSubscriptions = (subs: DeviceSubscription[]): DeviceSubscription[] => {
  // Returns subscriptions whose state field is not one of 'incomplete' or 'incomplete_expired'
  return subs.filter(sub => !['incomplete', 'incomplete_expired'].includes(sub.state));
};

/**
 * Filters a list of subscription for those that are valid and active.
 * @param subs 
 * @returns 
 */
export const activeSubscriptions = (subs: DeviceSubscription[]): DeviceSubscription[] => {
  return validSubscriptions(subs).filter(sub => !sub.canceledAt);
};

/**
 * First active valid subscription.
 * 
 * @param subs 
 * @returns 
 */
export const firstActiveSubscription = (subs: DeviceSubscription[]): DeviceSubscription | undefined => {
  return activeSubscriptions(subs)[0];
}