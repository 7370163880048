import React, { useEffect, useState }from 'react';

import { DeviceSubscription } from '../../models/Device';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQueryClient } from 'react-query';

import { User } from '../../models/User';
import TextField from '@mui/material/TextField';

interface ChangeEmailMutationFnParams { 
  userId: string;
  newEmail: string;
}

/**
 * Make change email request
 */
const changeEmailMutationFn = async ({ userId, newEmail }: ChangeEmailMutationFnParams): Promise<{}> => {
    const response = await ShieldGPSAxiosClient.post<DeviceSubscription>(
      '/admin/user-search/change-user-email', {
        userId,
        newEmail,
      });
  
    return response.data;
  };

export interface ChangeEmailDialogParams {
  open: boolean,
  user: User,
  handleClose: any,
  handleConfirm: any,
};

/**
 * Displays small dialog to change a users email.
 * 
 * @param param0 
 * @returns 
 */
export const ChangeEmailDialog = ({ open, user, handleClose, handleConfirm }: ChangeEmailDialogParams) => {
    const queryClient = useQueryClient();
    const [ email, setEmail ] = useState('');

    const isTracksolid = user.platform === 'tracksolid';
    const hasEmailChanged = user.email !== email;

    useEffect(() => {
      setEmail(user.email);
    }, [user]);

    // When submitting reactivation
    const changeEmailMutation =  useMutation({
      mutationFn: changeEmailMutationFn,
      onSuccess: () => {
        console.log(`invalidating query: user-lookup ${user.id}`);
        queryClient.invalidateQueries({ queryKey: ['user-lookup', user.id.toString()] })
        handleConfirm();
      }
    });
  
    const handleChangeEmail = () => {
      changeEmailMutation.mutate({ userId: user.id.toString(), newEmail: email });
    }
  
    // To handle the closing of a window (since closing does not actually unmount)
    useEffect(() => {
      // On close
      if (open) {
        setEmail(user.email);
      } else {
        changeEmailMutation.reset();      // Clear any errors
      }
      // Intentionally leave changeEmailMutation from dependency, since block itself changes it
    }, [open]);

    const handleEmailTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    };
  

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Email
        </DialogTitle>
        { isTracksolid && (
          <Alert severity="warning">
          This is a Tracksolid account, changing the email will create a new account in Tracksolid 
          with a default password of <strong>888888</strong>, and will resync all devices to that account. 
          If you proceed, check the re-sync succeeds.
        </Alert>
        )}
        
        <div style={{width: '600px'}}>
        <DialogContent>
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={handleEmailTextFieldChange}
                  fullWidth/>
          </DialogContent>
        </div>


        {changeEmailMutation.isError && (
          <Alert severity="error">Failed to change email. Most likely a Tracksolid problem, perhaps email address already taken?</Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose}>
            Don't Change
          </Button>
          <LoadingButton
            onClick={handleChangeEmail}
            loading={changeEmailMutation.isLoading}
            variant="contained"
            disabled={!hasEmailChanged}
            autoFocus>
            Change Email
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }