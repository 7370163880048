import { UseQueryResult, useQuery } from "react-query";
import ShieldGPSDeviceStatsAxiosClient from "../lib/ShieldGPSDeviceStatsAxiosClient";
import moment from "moment";

const METERS_PER_MILE = 1609;

export interface DeviceOdometer { 
  mileageMeters: number;
  mileageKilometers: number;
  mileageMiles: number;
  lastResetAt: Date;
}

interface ApiResponse {
  mileageMeters: number;
  lastResetAt: string;
}

/**
 * API query to retrieve device odometer.
 */
const getDeviceOdometer = async (imei: string): Promise<DeviceOdometer | null> => {
    const response = await ShieldGPSDeviceStatsAxiosClient.get<ApiResponse>(`/devices/${imei}/odometer`);

    const { mileageMeters, lastResetAt } = response.data;

    return {
      mileageMeters,
      mileageKilometers: mileageMeters / 1000,
      mileageMiles: mileageMeters / METERS_PER_MILE,
      lastResetAt: moment(lastResetAt).toDate(),
    }
}

/**
 * React hook that will fetch and refresh a device odometer.
 * @param imei 
 * @returns 
 */
export const useDeviceOdometer = (imei: string): UseQueryResult<DeviceOdometer, unknown> => {
  return useQuery(['useDeviceOdometer', imei], () => getDeviceOdometer(imei), {
    enabled: !!imei,
    retry: false,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
}
