import styled from 'styled-components'
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

import { DeviceBatteryMode } from '../../models/Device';

const TagContainer = styled.span<{fg: string, bg: string}>`
  display: inline-flex;
  align-items: center;
  gap: 3px;
  background-color: ${props => props.bg};
  color: ${props => props.fg};
  font-weight: 500;
  font-size: 8pt;
  border-radius: 5px;
  padding: 3px 8px;
  margin: 2px;
`;

interface DeviceBatteryModeTagProps {
  batteryMode: DeviceBatteryMode;
};

export const DeviceBatteryModeTag = ({ batteryMode }: DeviceBatteryModeTagProps ) => {
  const parts = batteryMode.split('-');
  const label = parts[parts.length - 1];

  return (
    <TagContainer fg="#414552" bg="#d6ffff">
      <AlarmOnIcon sx={{color: '#414552', fontSize: '12px'}} />{label}
    </TagContainer>
  );
}