import { User } from './User';

export type DeviceSignal = 0 | 1 | 2 | 3 | 4;

export interface DevicePosition {
  id: number;
  latitude: number;
  longitude: number;
  speed: number;
  battery?: number;
};

export interface DeviceSubscription { 
  id: number;
  stripeId: string;
  createdAt: string;
  canceledAt?: string;
  
  // Indicates scheduled cancelation
  cancelAt?: string;
  cancelationMethod?: string;
  state: string;
  plan: Plan;
}

export interface DeviceEvent {
  id: number;
  type: string;
  createdAt: string;
  eventAt: string;
}

export interface GetDeviceEventsResponse {
  data: DeviceEvent[];
  next?: string;
}

export type DeviceConfigSyncState = 'new' | 'sent' | 'received' | 'failed';

export type DeviceBatteryMode = 
  | 'live-tracking-10s'
  | 'live-tracking-30s'
  | 'live-tracking-60s'
  | 'fixed-interval-1hr'
  | 'fixed-interval-6hr'
  | 'fixed-interval-12hr';


export interface DeviceConfig {
  id: string;
  createdAt: Date;
  syncState: DeviceConfigSyncState;
  data: any;
  issuedCommands: DeviceCommand[];
}

export interface DeviceCommand {
  id: number;
  command: string;
  status: string;
  direction: string;
  medium: string;
  createdAt: Date;
  response?: DeviceCommand;
}

export interface Device {
  id: number;
  imei: string;
  make: string;
  model: string;
  state: string;
  name: string;
  lastseen: string;
  status: string;
  simProvider: string;
  iccid: string;
  dataQuotaBytesUsed: number;
  dataQuotaBytesTotal: number;
  smsQuotaUsed: number;
  smsQuotaTotal: number;
  storageUsedBytes: number;

  simStatus?: string;
  simStatusLastRefreshedAt?: string;
  simExpiresAt?: string;

  plan?: Plan;
  owner?: User;
  lastPosition?: DevicePosition;
  deviceSubscriptions: [DeviceSubscription];

  tracksolidSynced: boolean;

  // Populated if the device has a battery and reports it in heartbeats. Secondary
  // to this, if device has a battery it may be reported solely in the position (ATA's do that)
  battery?: number;

  deviceConfigSyncState?: DeviceConfigSyncState;

  latestDeviceconfig?: DeviceConfig;

  // Whether device battery is charging or not. Supported by AT1
  charging?: boolean;

  // Signal strength of the device. Supported by AT1 only right now
  signal?: DeviceSignal;
}

export interface Plan {
  id: string;
  price: number;
  currency: string;
  name: string;
  interval: string;
}

export interface DeviceActivationEvent {
  id: string;
  type: string;
  device: Device;
  user: User;
  subscription: DeviceSubscription;
  eventAt: string;
}


export const deviceBattery = (device?: Device): number | null => {
  return device?.battery || device?.lastPosition?.battery || null;
}

export const deviceBatteryMode = (device?: Device | null): DeviceBatteryMode | null => {
  if (!device?.latestDeviceconfig || !device?.latestDeviceconfig.data?.batteryMode) {
    return null;
  }

  return device.latestDeviceconfig.data.batteryMode;
}