import React, { useEffect, useState }from 'react';

import { Device, DeviceSubscription, Plan } from '../../models/Device';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';


import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import {default as MUIBox} from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export interface GetAvailablePlansResponse {
    availablePlans: Plan[],
}

/**
 * Fetch available plans for user + device.
 * @param userId 
 * @param deviceId 
 * @returns 
 */
const getAvailablePlans = async (userId: string, deviceId: string): Promise<Plan[]> => {

    const response = await ShieldGPSAxiosClient
        .get<GetAvailablePlansResponse>(`/admin/user-search/device-available-plans?userId=${userId}&deviceId=${deviceId}`);

    return response.data.availablePlans;
  };
  

/**
 * Make reactivation request
 */
const reactivateDevice = async ({ deviceId, planId }: { deviceId: string, planId: string}): Promise<{}> => {
    const response = await ShieldGPSAxiosClient.post<DeviceSubscription>(
      '/admin/user-search/reactivate-device', {
        deviceId,
        planId,
      });
  
    return response.data;
  };

export const ReactivateDeviceDialog = ({ open, device, handleClose, handleConfirm }: {
    open: boolean,
    device: Device,
    handleClose: any,
    handleConfirm: any,
  }) => {
    const queryClient = useQueryClient();
    const [ selectedPlanId, setSelectedPlanId ] = useState<string | undefined>(undefined);

    // Precondition of this dialog shoudl ensure userId present
    const userId: string = device.owner?.id.toString() || '';
    const deviceId = device.id.toString();
  
    // Retrieve available plans for user + device
    const { data: availablePlans, isLoading: availablePlansIsLoading } = useQuery({
      enabled: open,
      queryKey: ['available-plans', userId, deviceId],
      queryFn: () => getAvailablePlans(userId, deviceId),
    });

    useEffect(() => {
      if (availablePlans && availablePlans.length > 0) {
        setSelectedPlanId(availablePlans[0].id);
      }
    }, [availablePlans]);

    // When submitting reactivation
    const reactivateMutation =  useMutation({
      mutationFn: reactivateDevice,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['device-lookup', device.imei] });
        handleConfirm();
      }
    });
  
    const handleReactivate = () => {
      if (!selectedPlanId) {
        return;
      }

      reactivateMutation.mutate({ deviceId: device.id.toString(), planId: selectedPlanId });
    }
  
    // To handle the closing of a window (since closing does not actually unmount)
    useEffect(() => {
      if (!open) {
        reactivateMutation.reset();
        setSelectedPlanId(undefined);
      }
      // Intentionally leave closeMutation from dependency, since block itself changes it
    }, [open]);
  

    const handleChange = (event: SelectChangeEvent) => {
      setSelectedPlanId(event.target.value);
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Reactivate Device
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{width: '500px', paddingTop: '10px' }}>
              { availablePlansIsLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedPlanId}
                      label="Plan"
                      onChange={handleChange}
                    >
                      {availablePlans && availablePlans.map(plan => (
                        <MenuItem value={plan.id}>{plan.name} / {plan.price} {plan.currency} {plan.interval}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
        {reactivateMutation.isError && (
              <Alert severity="error">Unable to reactivate</Alert>
            )}
        <DialogActions>
          <Button onClick={handleClose}>
            Don't Reactivate
          </Button>
          <LoadingButton
            onClick={handleReactivate}
            loading={reactivateMutation.isLoading}
            disabled={!selectedPlanId}
            variant="contained"
            autoFocus>
            Reactivate Device
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }