import moment from 'moment-timezone';
import { useMemo } from 'react';
import { Bar, BarChart, Tooltip, XAxis } from 'recharts';
import { METERS_PER_MILE, useDeviceOdometerHistory } from '../../hooks/useDeviceOdometerHistory';
import { GraphContainer, GraphDescription, GraphTitle } from './GraphParts';

export enum Granularity{
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  MONTHS = "MONTHS",
};

const MAPPING: Record<Granularity, moment.unitOfTime.StartOf> = {
  [Granularity.MINUTES]: 'minute',
  [Granularity.HOURS]: 'hour',
  [Granularity.DAYS]: 'day',
  [Granularity.MONTHS]: 'month'
};

const lowerBound = (date: Date, granularity: Granularity, timezone: string): Date => {
  const startAtKey = MAPPING[granularity] || 'minute';
  const result = moment(date).tz(timezone).startOf(startAtKey).toDate();
  console.log(`Lower bound ${date.toISOString()} in timezone ${timezone} -> ${result.toISOString()} `);
  return result;

}

const upperBound = (date: Date, granularity: Granularity, timezone: string): Date => {
  const endAtKey = MAPPING[granularity] || 'minute';
  return moment(date).tz(timezone).endOf(endAtKey).toDate();
}

interface DeviceMileageBarChartProps {
  imei: string;
  granularity: Granularity;
  timezone: string;
  startAt: Date;
  endAt: Date;
}

export const DeviceMileageBarChart: React.FC<DeviceMileageBarChartProps> = ({ imei, startAt, endAt, granularity, timezone }) => {
  const computedStartAt = useMemo(() => lowerBound(startAt, granularity, timezone), [startAt, granularity, timezone]);
  const computedEndAt = useMemo(() => upperBound(endAt, granularity, timezone), [endAt, granularity, timezone]);

  const dateFormatStr = useMemo(() => granularity === Granularity.HOURS ? 'MM/DD @ HH:mm Z' : 'MM/DD', [granularity]);

  const { data: odometerHistory, isLoading, error } = 
    useDeviceOdometerHistory({ imei, startAt: computedStartAt, endAt: computedEndAt, granularity, timezone });

  const odometerHistoryFormatted = useMemo(() => {
    if (!odometerHistory?.data) {
      return [];
    }

    return odometerHistory.data.map(datapoint => ({
      ...datapoint,
      distanceMiles: datapoint.distanceMeters / METERS_PER_MILE,
      time: moment.tz(datapoint.key, timezone).toDate(),
    }));

  }, [odometerHistory]);

  console.log(odometerHistoryFormatted);

  if (error) {
    return <p>Failed to load data</p>;
  }

  return (
    <GraphContainer>
      <GraphTitle>Mileage {granularity === Granularity.HOURS ? '(per hour)' : '(per day)'}</GraphTitle>
      <BarChart width={600} height={80} data={odometerHistoryFormatted}>
        <Bar dataKey="distanceMiles" fill="#8884d8" />
        <XAxis 
          dataKey="time" 
          hide={true} 
          //domain = {['auto', 'auto']}
          //padding="no-gap"
          name = 'Time'
          tickLine={true}
          allowDataOverflow={false}
          //tick={{stroke: 'red', strokeWidth: 1}}
          />
        <Tooltip formatter={(value: number) => value.toFixed(1)} labelFormatter={(value: Date) => moment(value).format(dateFormatStr)}/>
      </BarChart>
      <GraphDescription>
        Mileage traveled by the device. Probably reasonably accurate for the FMT100, somewhat accurate for VO1, least accurate for OB22, AT1 etc.
      </GraphDescription>
    </GraphContainer>
  );
};
