import { 
  Text,
} from './Common';

import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';

interface BatteryIndicatorRange {
  start: number,
  end: number,
  icon: any,
};
  
const ranges: BatteryIndicatorRange[] = [
  { start: 0, end: 4, icon: Battery0BarIcon },
  { start: 4, end: 25, icon: Battery1BarIcon },
  { start: 25, end: 37.5, icon: Battery2BarIcon },
  { start: 37.5, end: 50, icon: Battery3BarIcon },
  { start: 50, end: 62.5, icon: Battery4BarIcon },
  { start: 62.5, end: 75, icon: Battery5BarIcon },
  { start: 75, end: 95, icon: Battery6BarIcon },
  { start: 95, end: 101, icon: BatteryFullIcon },
];
  
export const BatteryIndicator = ({ percent }: { percent : number }) => {
  const Icon = (ranges.find(def => percent >= def.start && percent < def.end) || ranges[0]).icon;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon fontSize='small' style={{ transform: 'rotate(90deg)', marginRight: '4px'}} sx={{color: '#414552'}} />
      <Text style={{ fontSize: '8pt' }}>{percent.toFixed(0)}%</Text>
    </div>
  );
}