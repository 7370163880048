import { createSlice } from '@reduxjs/toolkit';

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    imei: '',
  },
  reducers: {
    setDevice: (state, action) => {
      console.log('setting device');
      state.imei = action.payload;
    },
  },
});

export const { setDevice } = deviceSlice.actions;
export default deviceSlice.reducer;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDevice = (state: any) => state.device.imei