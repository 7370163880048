import axios from 'axios';

const ShieldGPSAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 5000,
  headers: {
    'X-Custom-Header': 'foobar',
  },
});

ShieldGPSAxiosClient.defaults.headers.common['Application-Name'] = 'ShieldGPS-Admin';
ShieldGPSAxiosClient.defaults.headers.common['Application-Version'] = '0.0.1';


const getAuthToken = (): string | null => {
  const authToken = window.localStorage.getItem('co.shieldgps.authToken');
  if(!authToken) {
    return null;
  }

  return `Bearer ${authToken}`;
};

ShieldGPSAxiosClient.interceptors.request.use((config) => {
  const authToken = getAuthToken();
  if(authToken) {
    if(!config.headers) {
      config.headers = {};
    }
    config.headers['Authorization'] = authToken!;
  }
  return config;
});

export default ShieldGPSAxiosClient;