import { Device } from '../../models/Device';

import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';

import { LoadingButton } from '@mui/lab';
import { CircularProgress, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { DeviceCommandRow, SectionHeaderText } from '../../components/Common';
import { useRecentDeviceCommands } from '../../hooks/useGetDeviceCommands';
import { useSendDeviceCommandMutation } from '../../hooks/useSendDeviceCommand';

interface DeviceCommandsTabProps {
  device: Device;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const NewCommandSection = styled.div`
  margin-bottom: 30px;
`;

const SendCommandBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 0.8em;
  color: rgb(0, 0, 0, 0.6);
  margin-bottom: 4px;
`;

export const DeviceCommandsList = ({device}: {device: Device}) => {
  const { data, isLoading, error } = useRecentDeviceCommands({ imei: device.imei });

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!data || !data.commands || data.commands.length === 0) {
    return (
      <div>No commands.</div>
    );
  }

  const commands = data.commands;

  return (
    <div>
      {commands.map((command) => (
        <DeviceCommandRow key={command.id} command={command} />
      ))}
    </div>
  );
};

export const DeviceCommandsTab: React.FC<DeviceCommandsTabProps> = ({ device }) => {

  const [command, setCommand] = useState<string>('');
  const [sendMode, setSendMode] = useState<string>('data');
  const textFieldInputRef = useRef<HTMLInputElement>(null); 
  
  const sendDeviceCommandMutation = useSendDeviceCommandMutation();

  useEffect(() => {
    textFieldInputRef.current?.focus();
  }, []);

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    sendDeviceCommandMutation.mutate({
      imei: device.imei,
      command,
      medium: sendMode,
    }, {
      onSuccess: () => {
        setCommand('');
        setTimeout(() => textFieldInputRef.current?.focus(), 100);
      }
    })
  };

  const onSendModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newSendMode: string,
  ) => {
    setSendMode(newSendMode);
  };

  return (
    <Container>
      <NewCommandSection>
        <SectionHeaderText>New Command</SectionHeaderText>

        <SendCommandBox>
          <TextField
          hiddenLabel
            id="newCommand"
            variant="outlined"
            disabled={sendDeviceCommandMutation.isLoading}
            value={command}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCommand(e.target.value)}
            onKeyDown={handleKeyPress}
            inputRef={textFieldInputRef}
            fullWidth/>

          <LoadingButton
            loading={sendDeviceCommandMutation.isLoading}
            loadingPosition="start"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={handleSubmit}>
              Send
          </LoadingButton>

        </SendCommandBox>

        <ToggleButtonGroup
          color="primary"
          value={sendMode}
          exclusive
          onChange={onSendModeChange}
          aria-label="Send Mode"
          style={{ maxHeight: '24px', marginTop: '12px' }}
          disabled={sendDeviceCommandMutation.isLoading}
        >
          <ToggleButton value="data">Data</ToggleButton>
          <ToggleButton value="sms">SMS</ToggleButton>
        </ToggleButtonGroup>
      </NewCommandSection>

      <div>
        <SectionHeaderText>Recent Commands</SectionHeaderText>
        <DeviceCommandsList device={device} />
      </div>
    </Container>
  );
};


/**
 * Direction (incoming, outgoing)
 * Command (text)
 * Status (new, issued, received, failed)
 * Medium (data, sms)
 * Time
 */


