import { useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, ComposedChart, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import { Device } from '../../models/Device';
import { GraphContainer, GraphDescription, GraphTitle, TimeRangeSelectorStyled } from './GraphParts';
import { DeviceMileageBarChart, Granularity } from './DeviceMileageBarChart';

const Root = styled.div`
  display: inline-block;
`;

// Contains a number of graphs
const Graphs = styled.div`
  display: flex;
  flex-direction: column;
`;

interface DeviceStatsBucket { 
  key: string;
  packetCount: number;
  outOfOrderCount: number;
  largePositionGapCount: number;
  packetCountBreakdown: Record<string, number>;
  averageLagMs: number;
  battery?: string;
}

interface DeviceStatsNormalizedBucket { 
  key: string;
  packetCount: number;
  positionPacketCount: number;
  outOfOrderCount: number;
  largePositionGapCount: number;
  averageLagMs: number;
  batteryVoltage?: number;
  batteryPercent?: number;
}

const normalizeBuckets = (buckets: DeviceStatsBucket[]): DeviceStatsNormalizedBucket[] => {
  return buckets.map((bucket: DeviceStatsBucket): DeviceStatsNormalizedBucket => {

    let batteryVoltage: number | undefined = undefined;
    let batteryPercent: number | undefined = undefined;

    if (bucket.battery) {
      batteryVoltage = Number(bucket.battery.split(':')[0]);
      batteryPercent = Number(bucket.battery.split(':')[1]);
    }

    return {
      key: bucket.key,

      // Just want to record if we have any packets in this time bucket or not
      packetCount: bucket.packetCount > 0 ? 1 : 0,
      positionPacketCount: bucket.packetCountBreakdown['pos'] || 0,
      largePositionGapCount: bucket.largePositionGapCount,

      averageLagMs: bucket.averageLagMs,
      outOfOrderCount: bucket.outOfOrderCount,
      batteryVoltage: batteryVoltage,
      batteryPercent: batteryPercent,
    };
  });
};

const calculateStartAt = (offsetDays: number): Date => {
  return new Date(new Date().getTime() - offsetDays*1000*60*60*24);
}

const buildDataUrl = (imei: string, offsetDays: number): string => {
  const startAt = calculateStartAt(offsetDays);
  return `https://device-status-service.k8testing.shieldgps.co/devices/${imei}?startAt=${startAt.toISOString()}`;
}

/**
 * 
 * @param param0 
 * @returns 
 */
function TimeRangeSelector({ currentSelection, onSelect }: { currentSelection: number, onSelect: (selection: number) => void }) {
  return (
    <div>
      <button disabled={currentSelection === 1} onClick={() => onSelect(1)}>1 Day</button>
      <button disabled={currentSelection === 3} onClick={() => onSelect(3)}>3 Day</button>
      <button disabled={currentSelection === 7} onClick={() => onSelect(7)}>7 Day</button>
    </div>
  );
}

export function DeviceDiagnostics({ device }: { device: Device }) {
  const imei = device.imei;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [result, setResult] = useState({ score: 0, buckets: [], normalizedBuckets: [] });
  const [durationDays, setDurationDays] = useState(1);

  const startAt = useMemo(() => calculateStartAt(durationDays), [durationDays]);
  
  const mileageGranularity = useMemo(() => durationDays >= 3 ? Granularity.DAYS : Granularity.HOURS, [durationDays])

  useEffect(() => {
    setIsLoading(true);

    fetch(buildDataUrl(imei || '', durationDays))
      .then(res => res.json())
      .then((json) => {
        setResult({
          ...json,
          normalizedBuckets: normalizeBuckets(json.buckets),
        });
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setIsError(true);
        console.log('Unable to proceed');
        
      });
  }, [imei, durationDays]);

  if (isError) {
    return <p>Failed to load data</p>;
  }

  return (
    <Root className={isLoading ? "loadingdiv" : ""}>
      <TimeRangeSelectorStyled style={{ textAlign: 'center' }}>
        <a target="_blank" rel="noreferrer" href={buildDataUrl(imei || '', durationDays)}>Raw Data</a>
        <TimeRangeSelector currentSelection={durationDays} onSelect={(days) => setDurationDays(days)} />
      </TimeRangeSelectorStyled>
      <Graphs>
        <GraphContainer>
          <GraphTitle>Uptime</GraphTitle>
          <ComposedChart width={600} height={80} data={result.normalizedBuckets}>
            <Bar dataKey="packetCount" fill="#8884d8" />
            <XAxis dataKey="key" hide={true} />
            <Tooltip/>
          </ComposedChart>
          <GraphDescription>
            Displays overall uptime / availability of a device. Each bar represents a 15 minute bucket. If no bar is present then the device was not connected during this time.
          </GraphDescription>
        </GraphContainer>

        <DeviceMileageBarChart
          imei={device.imei}
          startAt={startAt}
          endAt={new Date()}
          granularity={mileageGranularity}
          timezone='America/Los_Angeles' />

        <GraphContainer>
          <GraphTitle>Packet Lag</GraphTitle>
          <BarChart width={600} height={80} data={result.normalizedBuckets}>
            <Bar dataKey="averageLagMs" fill="#8884d8" />
            <XAxis dataKey="key" hide={true} />
            <Tooltip/>
            <ReferenceLine y={3000} label="" stroke="orange" />
            <YAxis hide={true} type="number" domain={[0, 3000]} />
          </BarChart>
          <GraphDescription>
            Displays the average packet lag for location packets only. Each bar represents a 15 minute bucket.
          </GraphDescription>
        </GraphContainer>

        <GraphContainer>
          <GraphTitle>Out of Order Packets</GraphTitle>
          <BarChart width={600} height={80} data={result.normalizedBuckets}>
            <Bar dataKey="outOfOrderCount" fill="#8884d8" />
            <XAxis dataKey="key" hide={true} />
            <Tooltip/>
          </BarChart>
          <GraphDescription>
            If a packet is received out of order then bars are displayed here. Normal operation should have no out of order packets.
          </GraphDescription>
        </GraphContainer>


        <GraphContainer>
          <GraphTitle>Position Packet Count</GraphTitle>
          <BarChart width={600} height={80} data={result.normalizedBuckets}>
            <Bar dataKey="positionPacketCount" fill="#568a3e" />
            <XAxis dataKey="key" hide={true} />
            <Tooltip/>
          </BarChart>
          <GraphDescription>
            Position packets differ to other other packets (e.g heartbeats) because they contain lat/lng positions. They are emitted more frequently when a device is moving.
          </GraphDescription>
        </GraphContainer>

        <GraphContainer>
          <GraphTitle>Large Gap Count</GraphTitle>
          <BarChart width={600} height={80} data={result.normalizedBuckets}>
            <Bar dataKey="largePositionGapCount" fill="#ff0000" />
            <XAxis dataKey="key" hide={true} />
            <Tooltip/>
          </BarChart>
          <GraphDescription>
            Tracks the number of times we receive a new lat/lng that is &gt; 500 meters away from the previous lat/lng. The fewer occurences the better.
          </GraphDescription>
        </GraphContainer>

        { ['AT1', 'ATA'].includes(device.model) ? (
          <GraphContainer>
            <GraphTitle>Battery</GraphTitle>
            <BarChart width={600} height={80} data={result.normalizedBuckets}>
              <Bar dataKey="batteryPercent" fill="#8884d8" />
              <YAxis dataKey="batteryPercent" hide={true} domain={[0, 100]} />
              <XAxis dataKey="key" hide={true} />
              <Tooltip/>
            </BarChart>
        </GraphContainer>
        ) : null}

      </Graphs>
    </Root>
  );
};
