import React, { useEffect, useState }from 'react';

import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { User } from '../../models/User';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

import DialogContentText from '@mui/material/DialogContentText';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import {default as MUIBox} from '@mui/material/Box';


export interface UserImpersonateDialogParams {
  open: boolean,
  user: User,
  handleClose: any,
};

/**
 * Main component.
 * @param param0 
 * @returns 
 */
export const UserImpersonateDialog = ({ open, handleClose, user }: UserImpersonateDialogParams) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Impersonate User
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Copy and paste the URL below into an <strong>Incognito</strong> window. 
          It will give you access to <strong>{user.email}</strong> for 5 minutes.
        </DialogContentText>
        <MUIBox paddingTop={2}>
          <UserImpersonateLink user={user} />
        </MUIBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UserImpersonateLink = ({ user }: { user: User }) => {
  const [ loading, setLoading ] = useState(true);
  const [ impersonateLandingUrl, setImpersonateLandingUrl ] = useState('...');

  useEffect(() => {
    console.log(`Generating user impersonation link for ${user.email}...`);

    ShieldGPSAxiosClient.post('/admin/user-search/create-impersonation-link', {
      userId: user.id,
    })
    .then((response) => {
      if(response?.data?.url) {
        setImpersonateLandingUrl(response.data.url);
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);


  if (loading) {
    return (
      <CircularProgress />
    );
  } else {
    return (
      <Input 
        disabled 
        fullWidth
        defaultValue={impersonateLandingUrl} 
        inputProps={{ 'aria-label': 'description' }} 
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => { 
                navigator.clipboard.writeText(impersonateLandingUrl)
              }}
            >
              <ContentCopyRoundedIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }
}
