import React from 'react';
import User from './User';

export interface AuthContextType {
  user: User | null,
  isAuthenticated: boolean,
  isLoading: boolean,
  signOut: () => void,
  setAuthToken: (token: string) => void,
};

const AuthContext = React.createContext<AuthContextType>(undefined!);
export default AuthContext;


