import { Device } from '../../models/Device';

import styled from 'styled-components';
import {
  DeviceSubscriptionSummary,
  SectionHeaderText
} from '../../components/Common';
import { firstActiveSubscription, validSubscriptions } from '../../lib/common';

interface DeviceSubscriptionsTabProps {
  device: Device;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const DeviceSubscriptionsTab: React.FC<DeviceSubscriptionsTabProps> = ({ device }: DeviceSubscriptionsTabProps) => {
  const activeSubscription = firstActiveSubscription(device.deviceSubscriptions);
  const inactiveSubscriptions = validSubscriptions(device.deviceSubscriptions).filter(sub => !activeSubscription || sub.id !== activeSubscription.id);

  return (
    <Container>
      <div>
        <SectionHeaderText>Active Subscription</SectionHeaderText>
        <div>
          {activeSubscription ? (
            <DeviceSubscriptionSummary
              subscription={activeSubscription} />
          ) : (<p>None</p>)}
        </div>
      </div>
      <div>
        <SectionHeaderText>Past Subscriptions</SectionHeaderText>
        <div>
          {inactiveSubscriptions && inactiveSubscriptions.length > 0 ? inactiveSubscriptions.map((sub) => (
            <DeviceSubscriptionSummary
              key={sub.id}
              subscription={sub} />
          )) : (<p>None</p>)}
        </div>
      </div>
    </Container>
  );
};


