import axios from 'axios';

/** An axios client that points to the device stats service */
const ShieldGPSDeviceStatsAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_DEVICE_STATS_API_BASE_URL,
  timeout: 5000,
  headers: {
  },
});

ShieldGPSDeviceStatsAxiosClient.defaults.headers.common['Application-Name'] = 'ShieldGPS-Admin';
ShieldGPSDeviceStatsAxiosClient.defaults.headers.common['Application-Version'] = '0.0.1';

const getAuthToken = (): string | null => {
  const authToken = window.localStorage.getItem('co.shieldgps.authToken');
  if(!authToken) {
    return null;
  }

  return `Bearer ${authToken}`;
};

ShieldGPSDeviceStatsAxiosClient.interceptors.request.use((config) => {
  const authToken = getAuthToken();
  if(authToken) {
    if(!config.headers) {
      config.headers = {};
    }
    config.headers['Authorization'] = authToken!;
  }
  return config;
});

export default ShieldGPSDeviceStatsAxiosClient;