import { QueryClient, useMutation, useQueryClient } from "react-query";
import ShieldGPSAxiosClient from "../lib/ShieldGPSAxiosClient";

interface SendDeviceCommandParams { 
  imei: string;
  command: string;
  medium: string;
}

interface SendDeviceCommandResponse {
  success: boolean;
}

const sendDeviceCommand = async ({ imei, command, medium }: SendDeviceCommandParams): Promise<{}> => {
  const response = await ShieldGPSAxiosClient.post<SendDeviceCommandResponse>(
    `/admin/user-search/devices/${imei}/commands`, {
      command,
      medium,
    });

  /* Simulate a delay */
  await new Promise(resolve => setTimeout(resolve, 500));

  return response.data;
};

export const useSendDeviceCommandMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendDeviceCommand,
    onSuccess: (_response, variables) => {
      const queryKey = ['recentDeviceCommands', variables.imei];
      queryClient.invalidateQueries({ queryKey });
    }
  });
};