import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Box,
  DeviceEventsSummary,
  LeftColumn,
  MainContainer,
  RightColumn,
  SectionHeaderText
} from '../../components/Common';
import ShieldGPSAxiosClient from '../../lib/ShieldGPSAxiosClient';
import { Device } from '../../models/Device';
import { DeviceDiagnostics } from './DeviceDiagnostics';

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { CancelDeviceDialog } from './CancelDeviceDialog';
import { DeviceSubscriptionsTab } from './DeviceSubscriptionsTab';
import { FactoryResetDeviceDialog } from './FactoryResetDeviceDialog';
import { ReactivateDeviceDialog } from './ReactivateDeviceDialog';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
  useParams
} from "react-router-dom";
import { firstActiveSubscription } from '../../lib/common';
import { DeviceCommandsTab } from './DeviceCommandsTab';
import { DeviceConfigTab } from './DeviceConfigTab';
import { DeviceProperties } from './DeviceProperties';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function DeviceActionsDropdown({ onActionSelect, reactivateEnabled, cancelEnabled, factoryResetEnabled }: {
  onActionSelect: (action: string) => void,
  reactivateEnabled: boolean,
  cancelEnabled: boolean,
  factoryResetEnabled: boolean,
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant="contained" 
        endIcon={<KeyboardArrowDownRoundedIcon />}
        aria-haspopup="menu">
          Actions
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper>
          <MenuList>
            <MenuItem 
              key={1} 
              onClick={() => {
                handleClose();
                onActionSelect('reconfigure');
              }}>
              Initialize
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem 
              key={2} 
              disabled={!reactivateEnabled}
              onClick={() => {
                handleClose();
                onActionSelect('reactivate');
              }}>
              Reactivate
            </MenuItem>
            <MenuItem
              key={3}
              disabled={!cancelEnabled}
              onClick={() => {
                handleClose();
                onActionSelect('cancel');
              }}>
              Cancel
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem
              key={4}
              disabled={!factoryResetEnabled}
              onClick={() => {
                handleClose();
                onActionSelect('reset');
              }}>
              Factory Reset
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}

function InitializeDeviceDialog({ open, handleClose, handleConfirm }: { open: boolean, handleClose: any, handleConfirm: any}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Initialize Device
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Feature not yet available. Check back soon!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const getDevice = async (imei: string | undefined): Promise<Device | null> => {
  if (!imei) {
    return null;
  }

  try {
    const response = await ShieldGPSAxiosClient.get<Device>(`/admin/user-search/devices/${imei}`);
    return response.data;
  } catch(error: any) {
    if (error.request.status === 404) {
      // No device
      return null;
    }

    // Just rethrow
    throw error;
  }
};

export function DeviceViewPage() {
  const { imei } = useParams();
  const [ showResetModal, setShowResetModal ] = useState(false);
  const [ currentTab, setCurrentTab ] = useState<number>(0);
  const [ showInitializeModal, setShowInitializeModal ] = useState(false);
  const [ showCancelModal, setShowCancelModal ] = useState(false);
  const [ showReactivateModal, setShowReactivateModal ] = useState(false);

  const { data: device, isLoading: deviceIsLoading, isError } = useQuery(
    ['device-lookup', imei],
    () => getDevice(imei),
    { retry: false },
  );

  // Don't rely on state since a few different values can indicate an 'active' subscription e.g active, trialing, past_due
  const activeSubscription = device ? firstActiveSubscription(device.deviceSubscriptions) : undefined;

  const handleActionSelect = (action: string) => {
    switch(action) {
      case 'cancel': {
        setShowCancelModal(true);
        break;
      }
      case 'reactivate': {
        setShowReactivateModal(true);
        break;
      }
      case 'reset': {
        setShowResetModal(true);
        break;
      }
      case 'reconfigure': {
        setShowInitializeModal(true);
        break;
      }
    }
  }

  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  }, [setCurrentTab]);

  return (
    <>
      <MainContainer>
        {deviceIsLoading && (
          <CircularProgress />
        )}

        {!deviceIsLoading && !device && isError && (
          <p>Error whilst loading device: <strong>{imei}</strong>.</p>
        )}

        {!deviceIsLoading && !device && !isError && (
          <p>Device not found: <strong>{imei}</strong>.</p>
        )}

        {!!device && !deviceIsLoading && (
          <>
           <LeftColumn>
            <DeviceProperties device={device} />
          </LeftColumn>

          <RightColumn>
            <Box style={{textAlign: 'right'}}>
              <DeviceActionsDropdown
                onActionSelect={handleActionSelect}
                reactivateEnabled={!activeSubscription && !!device?.owner}
                cancelEnabled={!!activeSubscription}
                // Can only factory reset if device is assigned to a user but no active subscription 
                factoryResetEnabled={true} />
            </Box>
            <Box>
              <Tabs value={currentTab} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="Diagnostics" />
                <Tab label="Subscriptions" />
                <Tab label="Activity" />
                <Tab label="Config" />
                <Tab label="Commands" />
              </Tabs>
            </Box>

            <TabPanel value={currentTab} index={0}>
              <DeviceDiagnostics device={device} />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <DeviceSubscriptionsTab device={device} />
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
              <SectionHeaderText>Recent Events</SectionHeaderText>
              <DeviceEventsSummary device={device} />
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
              <DeviceConfigTab device={device} />
            </TabPanel>

            <TabPanel value={currentTab} index={4}>
              <DeviceCommandsTab device={device} />
            </TabPanel>
          </RightColumn>
        </>
      )}
      </MainContainer>
      
      {device && (
        <FactoryResetDeviceDialog
          open={showResetModal}
          device={device}
          handleClose={() => setShowResetModal(false)}
          handleConfirm={() => setShowResetModal(false)} />
      )}

      <InitializeDeviceDialog
        open={showInitializeModal}
        handleClose={() => setShowInitializeModal(false)}
        handleConfirm={() => {}} />

      {device && !!device.owner && activeSubscription && (
        <CancelDeviceDialog
          open={showCancelModal}
          device={device}
          subscription={activeSubscription}
          handleClose={() => setShowCancelModal(false)}
          handleConfirm={() => {setShowCancelModal(false)}} />
      )}

      {device && !!device.owner && !activeSubscription && (
        <ReactivateDeviceDialog
          open={showReactivateModal}
          device={device}
          handleClose={() => setShowReactivateModal(false)}
          handleConfirm={() => setShowReactivateModal(false)} />
      )}
    </>
  );
};
