import { UseQueryResult, useQuery } from "react-query";
import ShieldGPSDeviceStatsAxiosClient from "../lib/ShieldGPSDeviceStatsAxiosClient";

export const METERS_PER_MILE = 1609;


enum Granularity{
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
  MONTHS = "MONTHS",
};

export interface OdometerHistoryDatapoint {
  key: string;
  numDatapoints: number;
  distanceMeters: number;
};

export interface GetOdometerHistoryResponse {
  startAt: Date,
  endAt: Date,
  queryTimeMs: number,
  granularity: Granularity,
  timezone: string,
  data: OdometerHistoryDatapoint[],
};

export interface GetDeviceOdometerHistoryRequestProps {
  imei: string;
  startAt?: Date,
  endAt?: Date,
  granularity?: string,
  timezone?: string,
};

export interface GetDeviceOdometerHistoryResponse {

}

/**
 * API query to retrieve device odometer.
 */
const getDeviceOdometerHistory = 
  async ({ imei, startAt, endAt, granularity, timezone }: GetDeviceOdometerHistoryRequestProps): Promise<GetOdometerHistoryResponse | null> => {

    let url = `/devices/${imei}/odometer/history?`;
    if (startAt) {
      url += `&startAt=${startAt.toISOString()}`;
    }

    if (endAt) {
      // url += `&endAt=${endAt.toISOString()}`;
    }

    if (granularity) {
      url += `&granularity=${granularity}`;
    }

    if (timezone) {
      url += `&timezone=${timezone}`;
    }

    console.log(url);
    const response = await ShieldGPSDeviceStatsAxiosClient.get<GetOdometerHistoryResponse>(url);
    return response.data;
}

/**
 * React hook that will fetch and refresh a device odometer.
 * @param imei 
 * @returns 
 */
export const useDeviceOdometerHistory = (requestProps: GetDeviceOdometerHistoryRequestProps): UseQueryResult<GetOdometerHistoryResponse, unknown> => {
  return useQuery(['useDeviceOdometerHistory', requestProps], () => getDeviceOdometerHistory(requestProps), {
    enabled: !!requestProps,
    retry: false,
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
  });
}
