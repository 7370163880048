import { UseQueryResult, useQuery } from "react-query";
import ShieldGPSAxiosClient from "../lib/ShieldGPSAxiosClient";
import { DeviceCommand } from "../models/Device";

export interface GetDeviceCommandsRequestProps {
  imei: string;
};

export interface GetDeviceCommandsResponse {
  commands: DeviceCommand[];
}

/**
 * API query to retrieve device odometer.
 */
const getRecentDeviceCommands = 
  async ({ imei }: GetDeviceCommandsRequestProps): Promise<GetDeviceCommandsResponse | null> => {
    let url = `/admin/user-search/devices/${imei}/commands`;
    const response = await ShieldGPSAxiosClient.get<GetDeviceCommandsResponse>(url);
    return response.data;
}

/**
 * React hook that will fetch and refresh a device odometer.
 * @param imei 
 * @returns 
 */
export const useRecentDeviceCommands = (requestProps: GetDeviceCommandsRequestProps): UseQueryResult<GetDeviceCommandsResponse, unknown> => {
  return useQuery(['recentDeviceCommands', requestProps.imei], () => getRecentDeviceCommands(requestProps), {
    enabled: !!requestProps,
    retry: false,
    refetchInterval: 5000,
    refetchIntervalInBackground: false,
  });
}
