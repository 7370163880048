import { useContext } from 'react';
import { useQuery } from 'react-query';
import AuthContext from '../lib/auth/AuthContext';
import ShieldGPSAxiosClient from '../lib/ShieldGPSAxiosClient';

const getServerStats = async () => {
  const response = await ShieldGPSAxiosClient.get('/admin/service-stats');
  return response.data;
};

const HomePage = () => {

  const authContext = useContext(AuthContext);

  const { data, isSuccess } = useQuery('server-stats', getServerStats, {
    refetchInterval: 5000,
  });

  return (
    <div>
      <h1>Device Stats</h1>
      <div className="statsSection">
        <StatsTile title="Subscribed" value={isSuccess ? data.num_active_devices : '--'} />
        <StatsTile title="Online" value={isSuccess ? data.num_online_devices : '--'} />
        <StatsTile title="Moving" value={isSuccess ? data.num_moving_devices : '--' } />
      </div>

      <h1>User Stats</h1>
      <div className="statsSection">
        <StatsTile title="Online" value={isSuccess ? data.num_online_users : '--'} />
      </div>
    </div>
  )
}

const StatsTile = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="statsTile">
      <h1>{value}</h1>
      <p>{title}</p>
    </div>
  );
};

export default HomePage;