import { UseQueryResult, useQuery } from "react-query";
import ShieldGPSAxiosClient from "../lib/ShieldGPSAxiosClient";
import { DeviceConfig } from "../models/Device";

export interface GetDeviceConfigsRequestProps {
  imei: string;
};

export interface GetDeviceConfigsResponse {
  configs: DeviceConfig[];
}

/**
 * API query to retrieve device odometer.
 */
const getDeviceConfigs = 
  async ({ imei }: GetDeviceConfigsRequestProps): Promise<GetDeviceConfigsResponse | null> => {
    let url = `/admin/user-search/devices/${imei}/configs`;
    const response = await ShieldGPSAxiosClient.get<GetDeviceConfigsResponse>(url);
    return response.data;
}

/**
 * React hook that will fetch and refresh a device odometer.
 * @param imei 
 * @returns 
 */
export const useGetDeviceConfigs = (requestProps: GetDeviceConfigsRequestProps): UseQueryResult<GetDeviceConfigsResponse, unknown> => {
  return useQuery(['getDeviceConfigs', requestProps], () => getDeviceConfigs(requestProps), {
    enabled: !!requestProps,
    retry: false,
    refetchInterval: 0,
    refetchIntervalInBackground: false,
  });
}
