
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import { DeviceSignal } from '../models/Device';
import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: end;
  font-size: 0.8em;
  gap: 4px;
`;

const SIGNAL_ICONS = {
  0: SignalCellular0BarIcon,
  1: SignalCellular1BarIcon,
  2: SignalCellular2BarIcon,
  3: SignalCellular3BarIcon,
  4: SignalCellular4BarIcon,
};

interface DeviceSignalIndicatorProps {
  signal: DeviceSignal;
  withDescription?: boolean
}

const describeSignalStrength = (signal: DeviceSignal): string => {
  switch (signal) {
    case 0:
      return 'No signal';
    case 1:
      return 'Weak';
    case 2:
      return 'Moderate';
    case 3:
      return 'Good';
    case 4:
      return 'Excellent';
    default:
      return 'Unknown';
  }
}

export const DeviceSignalIndicator = ({ signal, withDescription }: DeviceSignalIndicatorProps) => {

  const Icon = SIGNAL_ICONS[signal] || SignalCellular0BarIcon;

  return (
    <Container>
      <Icon fontSize='small' sx={{color: '#414552'}} />
      { withDescription && describeSignalStrength(signal) }
    </Container>
  );
}